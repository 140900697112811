<template>
  <div id="richText">
    <Editor id="tinymce" v-model="value" :init="init" />
  </div>
</template>

<script>
import tinymce from "tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/themes/silver/theme";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/icons/default";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/link";
import "tinymce/plugins/code";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/hr";
import "tinymce/plugins/paste";

export default {
  props: {
    initial: {
      type: String,
      default: "",
    },
  },
  components: {
    Editor,
  },
  watch: {
    initial: {
      handler(value) {
        this.value = value;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      value: "",
      init: {
        selector: "#tinymce",
        language: "zh_CN",
        language_url: "/tinymce/zh_CN.js",
        skin_url: "/tinymce/skins/ui/oxide",
        menubar: false,
        height: 500,
        branding: false,
        statusbar: false,
        paste_remove_styles_if_webkit: true,
        plugins: "link image media lists table wordcount code hr paste",
        toolbar:
          "fontselect fontsizeselect lineheight forecolor backcolor bold italic underline strikethrough hr|numlist bullist| alignleft aligncenter alignright alignjustify |link image media quicklink table",
        fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        images_upload_handler: this.imageUpload,
        file_picker_types: "media",
        file_picker_callback: this.filePickerCallback,
      },
    };
  },

  methods: {
    async imageUpload(blobInfo, success, fail) {
      const formData = new FormData();
      formData.append("file", blobInfo.blob());

      try {
        let res = await this.$http.post("/ad/pic/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (res.code === 0) {
          success(res.data.fileUrl);
        } else {
          fail(`图片上传失败,请联系管理员:${res.msg}`);
        }
      } catch (err) {
        fail(`图片上传失败,请联系管理员:${err}`);
      }
    },
    filePickerCallback(callback, value, meta) {
      if (meta.filetype == "media") {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute(
          "accept",
          "video/mp4,video/AVI,video/mov,video/FLV,video/rmvb,video/mtv,video/mpeg"
        );

        const _this = this;
        input.onchange = async function (e) {
          const loading = _this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
            customClass: "richLoading",
          });

          let file = this.files[0];
          let ext = file.name.split(".").slice(-1)[0].toLocaleLowerCase();
          if (
            ext !== "mp4" &&
            ext !== "AVI" &&
            ext !== "mov" &&
            ext !== "FLV" &&
            ext !== "rmvb" &&
            ext !== "wmv" &&
            ext !== "mtv" &&
            ext !== "mpg" &&
            ext !== "mpeg"
          ) {
            _this.$message.error(
              "上传资源只能是 avi/mov/rmvb/flv/mp4/wmv/mtv/mpg/mpeg 格式的视频!"
            );
            return false;
          }

          const formData = new FormData();
          formData.set("file", file);

          try {
            let res = await _this.$http.post("/ad/video/upload", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            if (res.code === 0) {
              callback(res.data.video.fileUrl);
            } else {
              _this.$message.error(res.msg);
            }
          } catch (err) {
            _this.$message.error({ message: "上传失败" });
            return;
          } finally {
            loading.close();
          }
        };
        input.click();
      }
    },
  },
};
</script>

<style>
.tox-tinymce-aux {
  z-index: 9999 !important;
}
.richLoading {
  z-index: 99999 !important;
}
</style>
